import SeoContext from '../lib/seo-context';
import { fetchAPI } from '../lib/api';
import { Merge } from '../lib/helper';

import Layout from '../components/layout';
import Hero from '../components/hero';
import HeroSlider from '../components/hero-slider';
import HeroSection from '../components/hero-section';
import Features from '../components/features';
import Title from '../components/title';
import ClientList from '../components/client-list';
import JobList from '../components/job-list';
import LargeBox from '../components/large-box';
import HeroVideo from '../components/hero-video';
import CtaBox from '../components/cta-box';
import TestimonialList from '../components/testimonial-list';
import ContentBoxList from '../components/content-box-list';
import FeaturedEvent from '../components/event-featured';
import EventList from '../components/event-list';
import NewsList from '../components/news-list';
import ContactBlock from '../components/contact-block';
import Team from '../components/team';
import Form from '../components/form';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const Home = (props) => {
    const { pageProps, global } = props;

    if (!global) {
        return;
    }
    const seo = Merge(global.seo, pageProps.seo);
    if (!seo.canonicalURL) seo.canonicalURL = `${process.env.NEXT_PUBLIC_SITE_URL}`;
    return (
        <SeoContext.Provider value={seo}>
            <Layout
                footerClass="skew-maturity-100"
                languageData={{ originalSlug: pageProps.originalSlug, languages: pageProps.localizations }}
            >
                {pageProps.header && pageProps.header[0].__component === 'layout.hero-slider' && (
                    <HeroSlider key="header" settings={pageProps.header[0]} className="home" />
                )}
                {pageProps.header && pageProps.header[0].__component === 'layout.hero' && (
                    <Hero key="header" settings={pageProps.header[0]} className="home" />
                )}

                {pageProps.header && pageProps.header[0].__component === "layout.hero-video" && (
                    <HeroVideo {...pageProps.header[0]}></HeroVideo>
                )}

                {pageProps.sections.map((section, index) => {
                    switch (section.__component) {
                        case 'layout.feature-list':
                            return <Features key={`feature-list-${index}`} {...section} />;
                        case 'layout.large-box':
                            return <LargeBox key={`large-box-${section.__component}-${section.id}-${index}`} {...section} />;
                        case 'layout.title':
                            return <Title key={`title-box-${section.id}`} {...section} />;
                        case 'layout.cta':
                            return <CtaBox key={`cta-box-${section.id}`} {...section} />;
                        case 'layout.testimonial-list':
                            return <TestimonialList key={`test-${section.__component}-${section.id}-${index}`} {...section} />;
                        case 'layout.client-list':
                            return <ClientList key={'cli' + section.id} settings={section} />;
                        case 'layout.hero':
                            return <HeroSection key={'hero-subpage'} {...section}></HeroSection>;
                        case 'layout.joblist':
                            return <JobList key={'job-list'} {...section}></JobList>;


                        case 'layout.events-list':
                            return (
                                <div key="event-list">
                                    <FeaturedEvent events={pageProps.events} />
                                    <EventList events={pageProps.events} {...section} />
                                </div>
                            );
                        case 'layout.news-list':
                            return <NewsList key="news-list" news={pageProps.news} {...section} />;
                        case 'layout.contact-block':
                            return <ContactBlock key="contact-block" {...section}></ContactBlock>;
                        case 'layout.form-section':
                            return (
                                <section key={'feat-' + section.id}>
                                    <div className="relative bg-maturity-100 pt-20 mt-20">
                                        <div className="skew-top"></div>
                                        <div className="container z-20 relative -top-20">
                                            <GoogleReCaptchaProvider
                                                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPCHA_SITE_KEY}
                                                language={router.locale}
                                                scriptProps={{
                                                    async: false,
                                                    defer: false,
                                                    appendTo: 'head'
                                                }}
                                            >
                                                <Form
                                                    key={'feat' + section.id}
                                                    {...section.form.data.attributes.form}
                                                ></Form>
                                            </GoogleReCaptchaProvider>
                                        </div>
                                    </div>
                                </section>
                            );
                        case 'layout.content-block':
                            return (
                                <section key={'content-' + section.id}>
                                    <div className="relative">
                                        <div
                                            className="container p-10 content"
                                            dangerouslySetInnerHTML={{ __html: section.content }}
                                        ></div>
                                    </div>
                                </section>
                            );
                        case 'layout.team-list':
                            return (
                                <section key={'content-' + section.id}>
                                    <Team people={section.people.data}></Team>
                                </section>
                            );
                        case 'layout.content-box-list':
                            return (
                                <section key={'content-box-list-' + section.id}>
                                    <ContentBoxList {...section}></ContentBoxList>
                                </section>
                            );

                    }
                })}
                {/* <Cta className="bg-maturity-100 relative pt-24 pb-8 text-center">
                    <p className="text-trust-900 text-3xl normal-case py-8 w-2/3 mx-auto font-semibold">
                        Do you want to know more about our products?
                    </p>
                    <Link href="/contact">
                        <a className="btn btn-lg action-full">Let&apos;s get in touch</a>
                    </Link>
                </Cta> */}
            </Layout>
        </SeoContext.Provider>
    );
};

export async function getStaticProps({ locale }) {
    try {
        const res = await fetchAPI('/pages/by-slug/home', { populate: 'deep,4', locale });

        return {
            props: { pageProps: res.data.attributes },
            revalidate: Number(process.env.NEXT_PUBLIC_PAGE_REVALIDATION) || 100
        };
    } catch (err) {
        console.log(err);
    }
    return {
        props: {},
        revalidate: Number(process.env.NEXT_PUBLIC_PAGE_REVALIDATION) || 100
    };
}

export default Home;
